<template lang="pug">
.editor.mt-2
  editor-menu-bar(:editor="editor" v-slot='{ commands, isActive, focused }')
    .menubar.btn-group(:class="{ 'is-focused ': focused } [alwaysShow ? '' : '.is-hidden']")
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.bold() }" @click='commands.bold')
        i.fa.fa-bold.fa-fw
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.italic() }" @click='commands.italic')
        i.fa.fa-italic.fa-fw
      button.btn.btn-outline-dark(v-if='allowLinks' :class="{ 'is-active': isActive.link() }" @click='commands.link')
        i.fa.fa-link.fa-fw
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.heading({ level: 3 }) }" @click='commands.heading({ level: 3 })')
        | H3
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.heading({ level: 4 }) }" @click='commands.heading({ level: 4 })')
        | H4
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.heading({ level: 5 }) }" @click='commands.heading({ level: 5 })')
        | H5
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.bullet_list() }" @click='commands.bullet_list')
        i.fa.fa-list-ul.fa-fw
      button.btn.btn-outline-dark(:class="{ 'is-active': isActive.ordered_list() }" @click='commands.ordered_list')
        i.fa.fa-list-ol.fa-fw
      button.btn.btn-outline-dark(@click='commands.undo')
        i.fa.fa-undo.fa-fw
      button.btn.btn-outline-dark(@click='commands.redo')
        i.fa.fa-redo.fa-fw

  editor-content(class="content" :editor="editor" :lang="lang")
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  BulletList,
  Bold,
  Italic,
  History,
  Placeholder,
  Link,
} from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    inputValue: {
      type: String,
      required: false,
      default: ""
    },
    lang: {
      type: String,
      default: "de",
      required: false
    },
    alwaysShow: {
      type: Boolean,
      default: false,
      required: false
    },
    allowLinks: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    inputValue: {
      handler(val) {
        if (this.editor && val !== this.editor.getHTML()) {
          this.editor.setContent(val)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      editor: null,
      extensions: [
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new BulletList,
        new Bold(),
        this.allowLinks ? new Link({
          openOnClick: false,
          autolink: true,
          rel: null,
        }) : null,
        new Italic(),
        new History(),
        new Placeholder({
          emptyNodeClass: 'is-empty text-muted',
          emptyNodeText: 'Schreibe etwas …',
          showOnlyWhenEditable: true
        })
      ].filter(Boolean)
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: this.extensions,
      content: this.inputValue,
      onUpdate: ({ getJSON, getHTML }) => {
        let content = getHTML(),
        json = getJSON().content;

        if (Array.isArray(json) && json.length === 1 && !json[0].hasOwnProperty("content")) {
          content = null;
        }
        this.proxyValue = content
      },
    })
    window.setTimeout(() => {
      this.$el.querySelectorAll("button").forEach((button) => {
        button.addEventListener("click", (e) => {
          e.preventDefault()
        })
      })
    }, 100)
    // in v1.27.0 noch kein onCreate event deshalb window.setTimeout
    // https://tiptap.dev/docs/editor/api/events
  },
  computed: {
    proxyValue: {
      get() {
        return this.inputValue
      },
      set(val){
        this.$emit('input', val)
        this.$emit('update:inputValue', val)
      }
    }
  },
  methods: {
    clearContent() {
      this.editor.clearContent(true)
      this.editor.focus()
    },
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
  .menubar {
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
    &.is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }
  }
  .content {
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    border: 1px solid #dbdbdb;
    display: block;
    max-width: 100%;
    min-width: 100%;
    padding: 0.625em;
    resize: vertical;
    background-color: white;
    border-radius: 4px;
  }
  .ProseMirror:focus {
    outline: none;
  }
  .editor {
    .is-editor-empty {
      height: 10vh;
    }
  }
</style>

