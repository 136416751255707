<template lang="pug">
  svg(
    xmlns="http://www.w3.org/2000/svg"
     class="app-icon"
    :width="width"
    :height="width"
    :viewBox="`0 0 ${icon.center[0]} ${icon.center[1]}`"
    :fill="fill"
    @click="$emit('click')"
    v-html="icon.path")
</template>

<script>
import icons from "./icons";

export default {
  name: "AppIcon",
  props: {
    name: {
      type: String,
      default: ""
    },
    width: {
      type: [String, Number],
      default: 24
    },
    fill: {
      type: String,
      default: "inherit"
    }
  },
  computed: {
    icon() {
      return icons[this.name.toLowerCase()]
    }
  }
};
</script>

<style>
</style>
