import { GraphQLClient } from "graphql-request"
import type { RequestMiddleware, ResponseMiddleware } from "graphql-request"
import { getSdk } from "@/graphql_queries/requests"
import Sentry from "@/utils/sentry"


const requestMiddleware: RequestMiddleware = (request) => {
  Sentry.addBreadcrumb({
    category: 'graphql',
    message: 'graphql request',
    level: 'info',
    data: {
      operationName: request.operationName,
      variables: request.variables,
      query: request.operationName ? null : request.body
    }
  })
  return request
}

const responseMiddleware: ResponseMiddleware = (response) => {
  if (!(response instanceof Error) && response.errors) {
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: 'graphql response error',
      level: 'error',
      data: response,
    })
  }
}

const client = new GraphQLClient("/graphql", { responseMiddleware, requestMiddleware })


const callback = () => {
  const csrfToken = document.querySelector(
    "meta[name=csrf-token]"
  ) as HTMLMetaElement | null
  client.setHeader("X-CSRF-Token", csrfToken?.content || "")
}
document.addEventListener("DOMContentLoaded", callback)
if (document.readyState === "complete") {
  callback()
}


export default getSdk(client)
